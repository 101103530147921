import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { idPrilVk, adressServera } from '../config';

const useStyles = makeStyles({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
});

const VkAuth = () =>{
  const classes = useStyles();
  return(
    <React.Fragment>
<Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
>
      <Typography variant="body1" gutterBottom>
        Авторизоваться через:
      </Typography>
      <a href={`https://oauth.vk.com/authorize?client_id=${idPrilVk}&display=page&redirect_uri=${adressServera}/auth/vkontakte/callback&scope=friends&response_type=code&v=5.102`}>    
      <Avatar alt="Remy Sharp" src="/vk.png" className={classes.avatar} />
      </a> 

      </Grid>   
    </React.Fragment>
  )
  
}

export default VkAuth;
