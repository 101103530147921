import * as React from 'react';
import {MultiGrid, CellMeasurer, CellMeasurerCache} from 'react-virtualized';

import {formatData1} from '../utils/format-data';
import './CellMeasurer.css';

export default class DynamicWidthMultiGrid extends React.PureComponent {

  constructor(props, context) {
    super(props, context);

    this._cache = new CellMeasurerCache({
      defaultHeight: 30,
      defaultWidth: 150,
      minWidth: 50,
      fixedHeight: true,
    });

    this._cellRenderer = this._cellRenderer.bind(this);
  }

  onClickDelete = (idEl, e) =>{
    const data = {
      cellData: this.props.data.data[idEl.rowIndex - 1]._id,
    }
    this.props.deleteElement(this.props.data.nameKey, data);
  }

  onClickEdit = (idEl, e) =>{
    this.props.editElement(this.props.data.data[idEl.rowIndex - 1]._id)
  }

  onChange1 = (rowIndex, columnIndex, nameTabl, e) =>{
    this.props.onChange1(rowIndex, columnIndex, nameTabl, e);
  }

  render() {

    const {width} = this.props;

    let columnCount = 0;
    let fixedColumn = 0;

    if (this.props.data.bezknopok) {
      columnCount = this.props.data.kolonki.length;
    } else {
      columnCount = this.props.data.kolonki.length + 1;
      fixedColumn = 1;
    }

    const rowCount = this.props.data.data.length + 1;

    return (
      <MultiGrid
        className="BodyGrid"
        columnCount={columnCount}
        columnWidth={this._cache.columnWidth}
        deferredMeasurementCache={this._cache}
        fixedColumnCount={fixedColumn}
        fixedRowCount={1}
        height={this.props.height}
        overscanColumnCount={0}
        overscanRowCount={0}
        cellRenderer={this._cellRenderer}
        rowCount={rowCount}
        rowHeight={30}
        width={width}
        data={this.props}
      />
    );
  }

  spanStyle(content, rowIndex) {
    let cls = "";
    let oforml = true;

    if (this.props.data.videlenie) {

      this.props.data.videlenie.usl.forEach((item)=>{
        oforml = oforml && this.props.data.data[rowIndex-1][item];

      })


      if (oforml) {
        cls = this.props.data.videlenie.color;
      }
      
    }


    return (
      <span className={cls}>{content}</span>
    )
  }

  inputEl(boolZnac, dis, rowIndex, columnIndex, nameTabl) {
    
    return(
      <input type="checkbox" checked={boolZnac} disabled={dis} onChange={(e) => this.onChange1(rowIndex, columnIndex, nameTabl, e)}/>
    )

  }

  inputElUser(value, rowIndex, columnIndex, nameTabl) {

    const users = this.props.dataAll.data.usergroups.data.find(item=>item._id=this.props.dataAll.tekMenuGroup.id).usergroups_table;

    return(
      // <input type="checkbox" checked={boolZnac} disabled={dis} onChange={(e) => this.onChange(rowIndex, columnIndex, nameTabl, e)}/>
      // <input value={value} onChange={(e) => this.onChange1(rowIndex, columnIndex, nameTabl, e)}/>
    
      <select className="form-control" value={value} onChange={(e) => this.onChange1(rowIndex, columnIndex, nameTabl, e)}>
        <option key='' value=''></option>
        {
          users.map(option => (
            <option key={option.email} value={option.email}>{`${option.fio} (${option.email})`}</option>
          ))
        }
      {/* {currencies.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))} */}
    </select>

    
    )

  }

  inputElStr(value, rowIndex, columnIndex, nameTabl) {
    
    return(
      // <input type="checkbox" checked={boolZnac} disabled={dis} onChange={(e) => this.onChange(rowIndex, columnIndex, nameTabl, e)}/>
      <input value={value} onChange={(e) => this.onChange1(rowIndex, columnIndex, nameTabl, e)}/>
    )

  }

  _cellRenderer({columnIndex, key, parent, rowIndex, style}) {
    const {getClassName} = this.props;

    const classNames = getClassName({columnIndex, rowIndex});

    let content = "";
    if (rowIndex===0){
      if (columnIndex===0 && !this.props.data.bezknopok) {
        content="#"
      } else if (this.props.data.bezknopok) {
        content = this.props.data.kolonki[columnIndex].label;
      } else {
        content = this.props.data.kolonki[columnIndex-1].label;        
      }

    } else {
      if (columnIndex===0 && !this.props.data.bezknopok){


        let knopki = [];
        if (!this.props.data.noDelete) {
          knopki.push(
            <button key='del' className="btn btn-outline-danger btn-icon btn-inline-block mr-1 btn-mini" onClick={(e) => this.onClickDelete({rowIndex}, e)}>
              <i className="fal fa-times" />
            </button>          
          )
        }
        knopki.push(
          <button key='edit' className="btn btn-outline-primary btn-icon btn-inline-block mr-1 btn-mini" onClick={(e)=> this.onClickEdit({rowIndex}, e)}>
            <i className="fal fa-edit" />
          </button>            
        )


        return (
          <CellMeasurer
            cache={this._cache}
            columnIndex={columnIndex}
            key={key}
            parent={parent}
            rowIndex={rowIndex}>
            <div
              className={classNames}
              style={{
                ...style,
                whiteSpace: 'nowrap',
              }}>
              
              <div className="d-flex demo">               
                {knopki}
              </div>


            </div>
          </CellMeasurer>
        );

      } else {
        let sm = 0;
        if (!this.props.data.bezknopok) {
          sm = 1;
        }


        if (this.props.data.kolonki[columnIndex-sm].type === 'data') {
          if (this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey]) {
            content = formatData1(new Date(this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey]));
          } else {
            content = '';
          }
        } else if (this.props.data.kolonki[columnIndex-sm].type === 'bool') {
          let dis = this.props.data.kolonki[columnIndex-sm].disabled;
          let boolZnac = this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey];
          content = this.inputEl(boolZnac, dis, rowIndex-1, columnIndex-sm, this.props.nameTabl);
        } else if (this.props.data.kolonki[columnIndex-sm].type === 'secret'){
          content = '*';
        } else if (this.props.data.kolonki[columnIndex-sm].type === 'tabluser') {
          content = '';
        } else if (this.props.data.kolonki[columnIndex-sm].type && this.props.data.kolonki[columnIndex-sm].editVStroke) {

          if (this.props.data.kolonki[columnIndex-sm].type === 'user') {
            content = this.inputElUser(this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey], rowIndex-1, columnIndex-sm, this.props.nameTabl);

          } else {
            content = this.inputElStr(this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey], rowIndex-1, columnIndex-sm, this.props.nameTabl);

          }

        
        
        } else if (this.props.data.kolonki[columnIndex-sm].type === 'user') {



          const email = this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey];
          const users = this.props.dataAll.data.usergroups.data.find(item=>item._id==this.props.dataAll.tekMenuGroup.id).usergroups_table;

          if (email=='') {
            content = ''
          } else {
            const fio = users.find(item=>item.email==email).fio;
            content = fio + ' (' + email + ')';
          }

                 
        } else if (this.props.data.kolonki[columnIndex-sm].type === 'ssilka') {
          const naimTabl = this.props.data.kolonki[columnIndex-sm].typeSsilka;
          

          const ssilka1 = this.props.dataAll.data[naimTabl].data.find(item=>item._id==this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey]);
          if (ssilka1) {
            if (ssilka1.name) {
              content = ssilka1.name
            } else {
              content = ssilka1._id;
            }
            
          } else {
            content = '';
          }

        
        } else if(this.props.data.kolonki[columnIndex-sm].type === 'tabl') {
          content = '';
        } else {



          content = this.props.data.data[rowIndex-1][this.props.data.kolonki[columnIndex-sm].dataKey];
          
          if (content) {
            if (content.length>100) {
              content= content.slice(0,100) + '...';
            }
          }
          content = this.spanStyle(content, rowIndex);
        }

      }

    }


    return (
      <CellMeasurer
        cache={this._cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}>
        <div
          className={classNames}
          style={{
            ...style,
            whiteSpace: 'nowrap',
          }}>
          {content}
        </div>
      </CellMeasurer>
    );
  }
}


