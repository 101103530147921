import React from 'react';
import { formatData } from '../utils/format-data';

export default function KalendarDopResurs(props) {

  if (!props.propPanel.dopResurs) {
    return null;
  } else if (props.propPanel.dopResurs==='$vremya'){
    const vrem1 = new Date(props.element[props.propPanel.izmerenie]);
    const vrem = formatData(vrem1).slice(11,16);
    return (
      <span className="fc-time">{vrem}</span>
    )
  } else {

    return (
      <span className="fc-time">{props.element[props.propPanel.dopResurs]}</span>
    )
  }

}
