import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { closeMsg, newError, zadatParol, soedinitTelegram, usergrouppriglprinato } from '../actions';
import ZadatParol from '../components/ZadatParol';

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  message: state.services.message,
});


const mapDispatchToProps = dispatch => bindActionCreators({
  zadatParol,
  closeMsg,
  newError,
  soedinitTelegram,
  usergrouppriglprinato,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZadatParol);
