import React from 'react';

import CellMeasurerExample from './CellMeasurerExample';

export default function TabPanelEl2({nameTabl, isAuthenticated, getTabl, ...props}) {
  return(
    <React.Fragment>

<div className="row">
        <div className="col-xl-12">
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row mb-3">
              <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-start">
                <div className="dt-buttons">
                  <button className="btn btn-success btn-sm mr-1" onClick={(e)=>props.onClickAddTabl(e, nameTabl)}>
                    <span>
                      <i className="fal fa-plus mr-1" />
                    </span>
                    Добавить
                  </button>

                </div>  

              </div>
            </div>
          </div>
        </div>  
      </div>

      <CellMeasurerExample 
        data = {props.data[nameTabl]}
        nameTabl = {nameTabl}

        height = {200}

        deleteElement = {props.deleteElement}
        onClickEditElement = {props.onClickEditElement}
        onChange1 = {props.onChange1}
        dataAll = {props.dataAll}
      />

    </React.Fragment>
  )
}