
import React from 'react';

export default function PassMaster(props) {

  const clsshow = "modal fade " + props.open;
  const onClickMimo = (e) => {  
    if (e.target===e.currentTarget) {
      props.onClickCloseElement();
    }
  }


  return(
    <div className={clsshow} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" onClick={onClickMimo}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Введите мастер пароль
            </h5>

            
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.onClickCloseElement}>
              <span aria-hidden="true">&times;</span>
            </button> 



          </div>
          <div className="modal-header">
            <div className="btn-group">
              <button className="btn btn-primary" onClick={props.zapisatPassword}>Записать</button>
            </div>
          </div>  
          <div className="modal-body">
           
            <form>
              <div className="form-group row">
                <label className="form-label col-sm-4 col-form-label text-left text-sm-right">
                  Введите пароль
                </label>
                <div className="col-lg-8">
                  <input className="form-control" type="password" onChange={(event) => props.setPassword(event.target.value)} value={props.password} autoComplete='off'/>         
                </div>
              </div> 
              <div className="form-group row">
                <label className="form-label col-sm-4 col-form-label text-left text-sm-right">
                  Повторите пароль
                </label>
                <div className="col-lg-8">
                  <input className="form-control" type="password" onChange={(event) => props.setRepeatPassword(event.target.value)} value={props.repeatPassword} autoComplete='off'/>         
                </div>
              </div> 
              
            </form>
          </div>

        </div>
      </div>

    </div>

  )
}