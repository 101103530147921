import React from 'react';

//import bcrypt from 'bcryptjs';


import TablPanelEl from './TablPanelEl';
import TablPanelEl2 from './TablPanelEl2';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

//import { syncScrypt } from '../utils/scrypt';
//import { Buffer } from '../utils/buffer';


const useStyles = makeStyles(theme => ({
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    
  },  
}));




export default function EditElementDialog(props) {

  const classes = useStyles();

  if (!props.dataForm) {
    return null;
  }  

  const clsshow = "modal fade " + props.open;

  const onClickMimo = (e) => {  
    if (e.target===e.currentTarget) {
      props.onClickCloseElement();
    }
  }

  let massivKnopok = null;
  let nameKnopka = 'Записать и закрыть';
  let noEdit = false;
  if (props.dataF.nameKey === 'obhodnoiList') {
    nameKnopka = 'Запустить на согласование';
  }

  if (props.dataF.noEditZapisan && props.dataForm['_id']) {
    noEdit = true;
  } else if (props.dataF.nameKey === 'soglasovat') {
    massivKnopok = (

      <div className="modal-header">
        <div className="btn-group">
          <button className="btn btn-primary" onClick={()=>props.zapisatElement(props.dataF.nameKey, 'soglasovano')}>Согласовано</button>
          <button className="btn btn-danger" onClick={()=>props.zapisatElement(props.dataF.nameKey, 'nesoglasovano')}>Не согласовано</button>
        
        </div>
      </div>  

          
    )
  } else {
    massivKnopok = (
      <div className="modal-header">
        <div className="btn-group">
          <button className="btn btn-primary" onClick={()=>props.zapisatElement(props.dataF.nameKey)}>{nameKnopka}</button>
        </div>
      </div>       
    )
  }


  const elements = props.dataF.kolonki.map((item, key)=>{

    switch(item.type){
      case 'stroka':
        let height = 3;
        if (item.height) {
          height = item.height;
        }
        if (height===1) {
          return(
            <div className="form-group row" key={item.dataKey}>
              <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
                {item.label}
              </label>
              <div className="col-lg-9">
                <input name={item.dataKey} className="form-control" disabled={item.disabled || noEdit} onChange={props.onChange} value={props.dataForm[item.dataKey]} />         
              </div>
            </div>            
          )
        } else {        
          return(
            <div className="form-group row" key={item.dataKey}>
              <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
                {item.label}
              </label>
              <div className="col-lg-9">
                <textarea name={item.dataKey} className="form-control" disabled={item.disabled || noEdit} rows={height} onChange={props.onChange} value={props.dataForm[item.dataKey]}></textarea>         
              </div>
            </div>
  
          )
        }
      case 'secret':
        return(
          <div className="form-group row" key={item.dataKey}>
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              {item.label}
            </label>
            <div className="col-lg-9">
              <textarea name={item.dataKey+'_rassifr'} className="form-control" rows={3} onChange={props.onChange} value={props.dataForm[item.dataKey+'_rassifr']}></textarea>         
            </div>
            {/* <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              {item.label + ' (зашифрованные)'}
            </label> */}
            {/* <div className="col-lg-9">
              <textarea name={item.dataKey} className="form-control" rows={3} onChange={props.onChange} value={props.dataForm[item.dataKey]}></textarea>         
            </div> */}
            {/* <div className="btn-group"> */}
            {/* <button className="btn btn-primary" onClick={()=>zaschifrovat(item.dataKey)}>Зашифровать</button> */}
            {/* </div> */}
          </div>

        )   
      
      case 'user':

        const users = props.data.usergroups.data.find(item=>item._id ==props.tekMenuGroup.id).usergroups_table;

        return(
          <div className="form-group row" key={item.dataKey}>
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              {item.label}
            </label>
            <div className="col-lg-9">
              {/* <input name={item.dataKey} className="form-control" onChange={props.onChange} value={props.dataForm[item.dataKey]} />          */}

              <select className="form-control" disabled={item.disabled || noEdit} name={item.dataKey} value={props.dataForm[item.dataKey]} onChange={props.onChange}>
                <option key='' value=''></option>
                { 
                  users.map(option=> (
                    <option key={option.email} value={option.email}>{`${option.fio} (${option.email})`}</option>
                  ))
                }
              </select> 
            </div>
          </div>            
        )
      case 'spisok':

        return(
          <div className="form-group row" key={item.dataKey}>
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              {item.label}
            </label>
            <div className="col-lg-9">
              {/* <input name={item.dataKey} className="form-control" onChange={props.onChange} value={props.dataForm[item.dataKey]} />          */}

              <select className="form-control" disabled={item.disabled} name={item.dataKey} value={props.dataForm[item.dataKey]} onChange={props.onChange}>
                <option key='' value=''></option>
                {
                  item.typeSpisok.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))
                }
                {/* { 
                  dataSsilki.map(option=> (
                    <option key={option._id} value={option._id}>{option.name}</option>
                  ))
                } */}
              </select> 
            </div>
          </div>   

        )
      case 'ssilka':
        const dataSsilki = props.data[item.dataKey].data;

        return(
          <div className="form-group row" key={item.dataKey}>
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              {item.label}
            </label>
            <div className="col-lg-9">
              {/* <input name={item.dataKey} className="form-control" onChange={props.onChange} value={props.dataForm[item.dataKey]} />          */}

              <select className="form-control" disabled={item.disabled} name={item.dataKey} value={props.dataForm[item.dataKey]} onChange={props.onChange}>
                <option key='' value=''></option>
                { 
                  dataSsilki.map(option=> (
                    <option key={option._id} value={option._id}>{(option.name)?option.name:option._id}</option>
                  ))
                }
              </select> 
            </div>
          </div>   
        )
      case 'bool':
        let check = false;
        if (props.dataForm[item.dataKey]) {
          check = true;
        }
        return(
          <div className="form-group row" key={item.dataKey}>
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              {item.label}
            </label>
            <div className="col-lg-9">
              <input type="checkbox" name={item.dataKey} className="form-control" onChange={props.onClickBool} checked={check} />         
              {/* <input type="checkbox" name={item.dataKey} className="form-control" onChange={props.onChange} value={props.dataForm[item.dataKey]} />          */}
            </div>
          </div>            
        )
      case 'data':
        let obrData = '';
        if (props.dataForm[item.dataKey]) {
          obrData = props.dataForm[item.dataKey].slice(0, 16);
        }
        return(
          <div key={item.dataKey} className="form-group row">
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              {item.label}
            </label>
            <TextField
              //id="datetime-local"
              type="datetime-local"
              disabled={item.disabled}
              value={obrData}
              name={item.dataKey}
              onChange={props.onChange}
              className={classes.textField1}
              InputLabelProps={{
                shrink: true,
              }}
            />   
            {/* <TextField
              //id="datetime-local"
              type="datetime-local"
              //value={obrData}
              //name={item.dataKey}
              defaultValue="2017-05-24T10:30"
              //onChange={props.onChange}
              className={classes.textField1}
              InputLabelProps={{
                shrink: true,
              }}
            />              */}
          </div>       
        )

      case 'tabl':

        return (
          <TablPanelEl2
          key = {item.dataKey}

          data = {props.dataForm}

          onChange1 = {props.onChange1}
          
          deleteElement = {props.deleteElement}
          getTabl = {props.getTabl}
          isAuthenticated = {props.isAuthenticated}

          nameTabl = {item.dataKey}

          onClickAdd = {props.handleClickOpen}  
          user = {props.user} 
          priglUser = {props.priglUser}  
          onChange = {props.onChange} 
          onClickAddTabl= {props.onClickAddTabl}
          dataAll = {props}      
        /> 
        )        

      case 'tabluser':
        return (
          <TablPanelEl
          key = {item.dataKey}

          data = {props.dataForm}

          onChange1 = {props.onChange1}
          
          deleteElement = {props.deleteElement}
          getTabl = {props.getTabl}
          isAuthenticated = {props.isAuthenticated}

          nameTabl = {item.dataKey}

          onClickAdd = {props.handleClickOpen}  
          user = {props.user} 
          priglUser = {props.priglUser}  
          onChange = {props.onChange}       
        /> 
        )
      default:
        return null
    }

  })  

  return(

    <div className={clsshow} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" onClick={onClickMimo}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {props.dataF.name}
            </h5>

            
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.onClickCloseElement}>
              <span aria-hidden="true">&times;</span>
            </button> 



          </div>
 
          {massivKnopok}
          <div className="modal-body">
           
            <form>

                {elements}
              
            </form>
          </div>

        </div>
      </div>

    </div>

  )

}
