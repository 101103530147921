import * as types from '../constants/auth';
import callApi from '../utils/call-api';

export function signup(email, msg){
  return (dispatch, getState) => {
    const {isFetching} = getState().services;
    if (isFetching.signup) {
     return Promise.resolve();
    }

    dispatch({
      type: types.SIGNUP_REQUEST
    })

    return callApi('/signup', undefined, {method: "POST"}, {
      email,
      msg
    })
      .then(json => {        
        dispatch({
          type: types.SIGNUP_SUCCESS,
          payload: json
        })  
      })
      .catch(reason => dispatch({
        type: types.SIGNUP_FAILURE,
        payload: reason
      }));
  };
}

export function zadatParol(email, tokenEmail, password, telegramToken, emailgruppatoken, idgroup){
  return (dispatch, getState) => {
    const {isFetching} = getState().services;
    if (isFetching.zadatParol) {
     return Promise.resolve();
    }

    dispatch({
      type: types.PAROL_REQUEST
    })

    const x = new Date();
    // Вычислим значение смещения текущего часового пояса в часах
    const caspoyas = -x.getTimezoneOffset();

    return callApi('/zadatparol', undefined, {method: "POST"}, {
      email,
      tokenEmail,
      password,
      caspoyas,
      telegramToken,
      emailgruppatoken,
      idgroup,
    })
      .then(json => {
        if (!json.token) {
          throw new Error('Джок токен!');
        }      
        localStorage.setItem('token', json.token);         
        dispatch({
          type: types.PAROL_SUCCESS,
          payload: json
        })  
      })
      .catch(reason => dispatch({
        type: types.PAROL_FAILURE,
        payload: reason
      }));
  };
}

export function soedinitTelegram(email, telegramtoken) {
  return (dispatch, getState) => {
    const {isFetching} = getState().services;
    if (isFetching.soedinitTelegram) {
      return Promise.resolve();
    }
   
    dispatch({
      type: types.SOEDINITTELEGRAM_REQUEST
    })
    return callApi('/soedinittelegram', undefined, {method: "POST"}, {
      email,
      telegramtoken
    })
      .then(json =>{
        dispatch({
          type: types.SOEDINITTELEGRAM_SUCCESS,
          payload: json
        })  
      })
      .catch(reason => dispatch({
        type: types.SOEDINITTELEGRAM_FAILURE,
        payload: reason
      }));
  }
}

export function usergrouppriglprinato(email, idgroup, emailgruppatoken) {
  return (dispatch, getState) => {
    const {isFetching} = getState().services;
    if (isFetching.usergrouppriglprinato) {
      return Promise.resolve();
    }
     
    dispatch({
      type: types.USERGROUPPRIGLPRINATO_REQUEST
    })
    return callApi('/usergroupprigl', undefined, {method: "POST"}, {
      email,
      idgroup,
      emailgruppatoken,
    })
      .then(json =>{
        dispatch({
          type: types.USERGROUPPRIGLPRINATO_SUCCESS,
          payload: json
        })  
      })
      .catch(reason => dispatch({
        type: types.USERGROUPPRIGLPRINATO_FAILURE,
        payload: reason
      }));
  }
}


export function login(email, password){
  return (dispatch, getState) => {
    const {isFetching} = getState().services;
    if (isFetching.login) {
      return Promise.resolve();
    }    
    dispatch({
      type: types.LOGIN_REQUEST
    })

    return callApi('/login', undefined, {method: "POST"}, {
      email,
      password,
    })     
      .then(json => {
        if (!json.token) {
          throw new Error('Джок токен!');
        }      
        localStorage.setItem('token', json.token);            
        
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: json
        })  
      })
      .catch(reason => dispatch({
        type: types.LOGIN_FAILURE,
        payload: reason
      }));
  };
}

export function logout(){

  return (dispatch) => {  
    dispatch({
      type: types.LOGOUT_SUCCESS
    })
  }; 
}

export function recieveAuth() {
  return(dispatch, getState) => {
    const {isFetching} = getState().services;
    if (isFetching.recieveAuth) {
      return Promise.resolve();
    }      
    const {token} = getState().auth;

    if (!token) {
      dispatch({
        type: types.RECIEVE_AUTH_FAILURE
      })     
    }


    return callApi('/userauth', token)
      .then(json => {      
        dispatch({
          type: types.RECIEVE_AUTH_SUCCESS,
          payload: json
        })  
      })
      .catch(reason => dispatch({
        type: types.RECIEVE_AUTH_FAILURE,
        payload: reason
      }));





  }
}

export function saveUser(lastName, firstName, patronymic, telefon, utccas, utcmin){
  return(dispatch, getState) => {
    const {isFetching} = getState().services;
    if (isFetching.saveUser) {
      return Promise.resolve();
    }      
    const {token} = getState().auth;
    if (!token) {
      dispatch({
        type: types.SAVE_USER_FAILURE
      })     
    }


    return callApi('/saveuser', token, {method: "POST"}, {
      lastName,
      firstName,
      patronymic,
      telefon,
      utccas,
      utcmin
    })
      .then(json => {       
        dispatch({
          type: types.SAVE_USER_SUCCESS,
          payload: json
        })  
      })
      .catch(reason => dispatch({
        type: types.SAVE_USER_FAILURE,
        payload: reason
      }));

  }  
}
