import React from 'react';

import KolonkaWindow from './KolonkaWindow';

export default function DashboardOblast(props) {

  if (props.leftMenu.length===0) {
    return null;
  }

  if (!props.leftMenu[props.menuIndex].kolonki) {
    return null;
  }

  const elements = props.leftMenu[props.menuIndex].kolonki.map((item, key)=>{

      return(
        <div key={item.id} className={item.typeVis}>
          <KolonkaWindow 

            item={item}
            tabl={props.tabl}
            tablsuccess={props.tablsuccess}
            zapisatElement={props.zapisatElement}
            deleteElement={props.deleteElement}
            getTabl={props.getTabl}
            errorZapisatEl={props.errorZapisatEl}
            isFetchingZapisatEl = {props.isFetchingZapisatEl}
            isAuthenticated = {props.isAuthenticated}
            user={props.user}
            onSaveSubmit = {props.onSaveSubmit}
            priglUser = {props.priglUser}
            newError = {props.newError}
            setMasterPassword = {props.setMasterPassword}
            masterPassword = {props.masterPassword} 
            tekMenuGroup = {props.tekMenuGroup}
          />

        </div>
       
      )

  })

  return(
    <React.Fragment>
        <div className="subheader">
          <h1 className="subheader-title">
            <i className={`ubheader-icon fal ${props.leftMenu[props.menuIndex].icon1}`}></i> 
            &nbsp;{props.leftMenu[props.menuIndex].name} 
          </h1>
        </div>  
        <div className="row">
          {elements}

                    
        </div>           
    </React.Fragment>
  )

}
