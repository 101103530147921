import React from 'react';

import KalendarDopResurs from './KalendarDopResurs';
import { formatData1 } from '../utils/format-data';

export default function KalendarPodr(props) {
  if (!props.open) {
    return null;
  }

  const dataP = new Date(props.dataPodr);
  const dataPStr = formatData1(dataP).slice(0, 10);

  let massivEl = props.data[props.propPanel.nameTabl].data.filter(function(elem) {

    let newDate = new Date(elem[props.propPanel.izmerenie]);

    return (newDate.getDate()===dataP.getDate() && newDate.getMonth()===dataP.getMonth() && newDate.getFullYear() === dataP.getFullYear());
  });

  let masEl1=[];
  massivEl.forEach(element => {
    masEl1.push(
      <button key={element._id} id={element._id} onClick={(e)=> props.onClickEditElement(e.currentTarget.id, e)} className="fc-day-grid-event fc-h-event fc-event fc-start fc-end bg-primary text-white border-primary fc-draggable b1">
        <div className="fc-content b2">
          <KalendarDopResurs 
            propPanel={props.propPanel} 
            element={element}
          />
          <span className="fc-title">{element.name}</span>
        </div>
      </button>

    )
  }); 

  return (
    <div className="fc-popover fc-more-popover card card-primary" style={props.koordinati}>
      <div className="fc-header card-header">
        <span className="fc-title">{dataPStr}</span>

        <i className="fc-close fal fa-times" onClick={props.onClickClosePodr}/>
  
      </div>
      <div className="fc-body card-body">
        <div className="fc-event-container">
          {masEl1}
        </div>
      </div>
    </div>
    
  )
}
