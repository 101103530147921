import React, { useEffect } from 'react';

import MaskedInput from 'react-text-mask';

import CircularDeterminate from './CircularDeterminate';

const currencies = [
  {
    value: 'UTC-12:00',
    label: 'UTC-12:00',
  },
  {
    value: 'UTC-11:00',
    label: 'UTC-11:00',
  },
  {
    value: 'UTC-10:00',
    label: 'UTC-10:00',
  },
  {
    value: 'UTC-09:30',
    label: 'UTC-09:30',
  },
  {
    value: 'UTC-09:00',
    label: 'UTC-09:00',
  }, 
  {
    value: 'UTC-08:00',
    label: 'UTC-08:00',
  },   
  {
    value: 'UTC-07:00',
    label: 'UTC-07:00',
  },    
  {
    value: 'UTC-06:00',
    label: 'UTC-06:00',
  },  
  {
    value: 'UTC-05:00',
    label: 'UTC-05:00',
  },  
  {
    value: 'UTC-04:00',
    label: 'UTC-04:00',
  },  
  {
    value: 'UTC-03:30',
    label: 'UTC-03:30',
  },  
  {
    value: 'UTC-03:00',
    label: 'UTC-03:00',
  }, 
  {
    value: 'UTC-02:00',
    label: 'UTC-02:00',
  },  
  {
    value: 'UTC-01:00',
    label: 'UTC-01:00',
  },    
  {
    value: 'UTC+00:00',
    label: 'UTC+00:00',
  },
  {
    value: 'UTC+01:00',
    label: 'UTC+01:00',
  },
  {
    value: 'UTC+02:00',
    label: 'UTC+02:00',
  },
  {
    value: 'UTC+03:00',
    label: 'UTC+03:00',
  },
  {
    value: 'UTC+03:30',
    label: 'UTC+03:30',
  },
  {
    value: 'UTC+04:00',
    label: 'UTC+04:00',
  },
  {
    value: 'UTC+04:30',
    label: 'UTC+04:30',
  },
  {
    value: 'UTC+05:00',
    label: 'UTC+05:00',
  },
  {
    value: 'UTC+05:30',
    label: 'UTC+05:30',
  },
  {
    value: 'UTC+05:45',
    label: 'UTC+05:45',
  },
  {
    value: 'UTC+06:00',
    label: 'UTC+06:00',
  },
  {
    value: 'UTC+06:30',
    label: 'UTC+06:30',
  },
  {
    value: 'UTC+07:00',
    label: 'UTC+07:00',
  },
  {
    value: 'UTC+08:00',
    label: 'UTC+08:00',
  },
  {
    value: 'UTC+08:45',
    label: 'UTC+08:45',
  },
  {
    value: 'UTC+09:00',
    label: 'UTC+09:00',
  },
  {
    value: 'UTC+09:30',
    label: 'UTC+09:30',
  },
  {
    value: 'UTC+10:00',
    label: 'UTC+10:00',
  },  
  {
    value: 'UTC+10:30',
    label: 'UTC+10:30',
  },  
  {
    value: 'UTC+11:00',
    label: 'UTC+11:00',
  },  
  {
    value: 'UTC+12:00',
    label: 'UTC+12:00',
  },  
  {
    value: 'UTC+12:45',
    label: 'UTC+12:45',
  },  
  {
    value: 'UTC+13:00',
    label: 'UTC+13:00',
  },  
  {
    value: 'UTC+14:00',
    label: 'UTC+14:00',
  },  


];


export default function NastroikiUser(props) {
  const [dataForm, setDataForm] = React.useState(null);
  const [refresh, setRefresh] = React.useState(true);
  const mask=['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  useEffect(() => {

    if (refresh && props.user) {

      let tel='';

      if (props.user.telefon) {
        let soot = true;
        for (let i = 0; i < props.user.telefon.length; i++) {
          
          if (typeof mask[i] == 'undefined') {
            soot = false;
          } else if (typeof mask[i] == 'string') {
            if (mask[i] !== props.user.telefon[i]){
              soot = false;
            }
          } else {
            if (!mask[i].test(props.user.telefon[i])) {
              soot = false;
            }
          }
        }

        if (soot) {
          tel = props.user.telefon;
        }

      }

      setRefresh(false);
      setDataForm({
        firstName: props.user.firstName, 
        lastName: props.user.lastName,   
        patronymic: props.user.patronymic,
        caspoyas: 'UTC' + props.user.utccas + ':' + props.user.utcmin,
        telefon: tel,       
     })
     }
    
  }, [props.user, mask, refresh]);

  const onChange =(event) => {
    let dataF = Object.assign({}, dataForm);
    dataF[event.target.name] = event.target.value;
    setDataForm(dataF);

  }

  if (!dataForm) {
    return(
      <CircularDeterminate />
    )
  } 

  return(
    <form onSubmit={(e)=>props.onSaveSubmit(e, dataForm)}>

      <div className="form-group row">
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              e-mail:
            </label>
            <div className="col-lg-9">
              <input className="form-control" value={props.user.email} disabled/>
            </div>
          </div>
          <div className="form-group row">
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              Фамилия:
            </label>
            <div className="col-lg-9">
              <input className="form-control" name="firstName" value={dataForm.firstName} onChange={onChange}/>
            </div>
          </div>
          <div className="form-group row">
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              Имя:
            </label>
            <div className="col-lg-9">
              <input className="form-control" name="lastName" value={dataForm.lastName} onChange={onChange}/>
            </div>
          </div>
          <div className="form-group row">
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              Отчество:
            </label>
            <div className="col-lg-9">
              <input className="form-control" name="patronymic" value={dataForm.patronymic} onChange={onChange}/>
            </div>
          </div>
          <div className="form-group row">
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              Телефон:
            </label>
            <div className="col-lg-9">
              <MaskedInput
                className="form-control"
                name="telefon"
                onChange={onChange}
                value={dataForm.telefon}
                mask={mask}
              />
            </div>
          </div>    


          <div className="form-group row">
            <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
              Часовой пояс:
            </label>
            <div className="col-lg-9">
              <select className="form-control" name="caspoyas" value={dataForm.caspoyas} onChange={onChange}>

                {currencies.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>

            </div>
          </div>
          <div className="form-group row el1">
            <button className="btn btn-primary">Записать</button>
          </div>

    </form>

  )
}
