import React from 'react';
import { Redirect } from 'react-router-dom';

import ZadatParolFunction from './ZadatParolFunction';
import CustomizedSnackbars from './CustomizedSnackbar';

class ZadatParol extends React.Component {
  state ={
    password: '',
    repeatPassword: '',   
  }

  onPasswordChahge = (e) =>{
    this.setState({
      password: e.target.value
    })
  }
  onRepeatPasswordChahge = (e) =>{
    this.setState({
      repeatPassword: e.target.value
    })
  }

  onZadatParol = (e) =>{
    e.preventDefault();
    let data = {};
    if(window.location.search) {
        let pair = (window.location.search.substr(1)).split('&');
        for(let i = 0; i < pair.length; i ++) {
            let param = pair[i].split('=');
            data[param[0]] = param[1];
        }
    }

    const { password, repeatPassword} = this.state;

    if (password.length < 6) {
      this.props.newError('Пароль должень быть от 6 до 20 символов');     
    } else if (password.length > 20) {
      this.props.newError('Пароль должень быть от 6 до 20 символов');      
    } else if (password !== repeatPassword) {
      this.props.newError('Не совпадает пароль и подтверждение!');
    } else {
      this.props.zadatParol(data.email, data.emailtoken, password, data.telegramtoken, data.emailgruppatoken, data.idgroup); 
    }

  }

  render() {

    if (this.props.isAuthenticated) {
      let data = {};
      if(window.location.search) {
          let pair = (window.location.search.substr(1)).split('&');
          for(let i = 0; i < pair.length; i ++) {
              let param = pair[i].split('=');
              data[param[0]] = param[1];
          }
      }   
      if (data.idgroup) {
        this.props.usergrouppriglprinato(data.email, data.idgroup, data.emailgruppatoken);
      } else if (data.telegramtoken) {
        this.props.soedinitTelegram(data.email, data.telegramtoken);
      }
    
      return(
        <Redirect
          to={{
            pathname: "/dashboard"
          }}
        />
      )
    }

    return(
      <React.Fragment>
        <ZadatParolFunction 
          onZadatParol={this.onZadatParol} 
          onClickRedirectAuth={this.onClickRedirectAuth}
          password={this.state.password}
          onPasswordChahge={this.onPasswordChahge}
          repeatPassword={this.state.repeatPassword}
          onRepeatPasswordChahge={this.onRepeatPasswordChahge}    
        />
        <CustomizedSnackbars 
          msg={this.props.message.msg}
          openMsg = {this.props.message.openMsg}
          variantMsg={this.props.message.variantMsg}
          closeMsg = {this.props.closeMsg}
        />
      </React.Fragment>
    )
  }
}

export default ZadatParol;
