import React, { useState } from 'react';

import Window from './Window';
import PassMaster from './PassMaster';

export default function KolonkaWindow(props) {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');  
  const [open, setOpen] = useState('');

  const zapisatPassword = () => {
    if (password !== repeatPassword) {
      props.newError('Пароль и подтверждение не совпадают');
    } else {
      props.setMasterPassword(password);
      setOpen('');
    }

  }

  const pressLock = () => {
    setOpen('show dop1');
    setPassword('');
    setRepeatPassword('');
  }

  const handleClose = () => {
    setOpen('');
  };
  
  if (!props.item.paneli) {
    return null;
  }


  const elements = props.item.paneli.map((item1, key)=>{
    

    let knopki = null;
    if (item1.icon && item1.icon === 'lock') {
      if (props.masterPassword.length > 0) {
        knopki = <div className="panel-toolbar"> 
        <button className="btn btn-panel" onClick={pressLock}>
          <i className="fa fa-unlock fa-lg green" />
        </button>
      </div>      
      } else {
      knopki = <div className="panel-toolbar"> 
      <button className="btn btn-panel" onClick={pressLock}>
        <i className="fal fa-lock fa-lg red" />
      </button>
    </div> 
      }

    } else {
      knopki =  <div className="panel-toolbar">
                  <button className="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                  <button className="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                  <button className="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
                </div>
    }

    return(
      <div key={item1.id} className="panel">
        <PassMaster 
          open={open}
          onClickCloseElement={handleClose}
          password={password}
          setPassword={setPassword}
          repeatPassword={repeatPassword}
          setRepeatPassword={setRepeatPassword}
          zapisatPassword = {zapisatPassword}
        />
      <div className="panel-hdr">
        <h2>{item1.name}
        </h2>
        {knopki}
        {/* <div className="panel-toolbar">

          <button className="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
          <button className="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
          <button className="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button>
        </div> */}
      </div> 
      <div className="panel-container show">
        <div className="panel-content">      
          <Window 
            propPanel={item1}
            data = {props.tabl}
            tablsuccess = {props.tablsuccess}
            zapisatElement = {props.zapisatElement}
            deleteElement = {props.deleteElement}
            getTabl = {props.getTabl}
            errorZapisatEl = {props.errorZapisatEl}
            isFetchingZapisatEl = {props.isFetchingZapisatEl}
            isAuthenticated = {props.isAuthenticated} 
            user = {props.user} 
            onSaveSubmit = {props.onSaveSubmit}    
            priglUser = {props.priglUser}  
            newError = {props.newError} 
            masterPassword = {props.masterPassword} 
            tekMenuGroup = {props.tekMenuGroup} 
          /> 
        </div>
      </div>
    </div>
    )
  })

  return(
    <React.Fragment>
      {elements}
    </React.Fragment>
    
  )

}
