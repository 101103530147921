import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { onClickMenu, zapisatElement, deleteElement, closeMsg, getTabl, saveUser, logout, priglUser, newError, onClickGroupMenu, setMasterPassword } from '../actions';
import DashBoardPage from '../components/DashBoardPage';

const mapStateToProps = state => ({
  menuIndex: state.services.menuIndex.leftMenuIndex,
  leftMenu: state.services.menu.leftMenu,
  nastroiki: state.services.nastroiki,
  tekMenuGroup: state.services.tekMenuGroup,
  tabl: state.dashboard.tabl.tabl,
  tablsuccess: state.dashboard.tablsuccess,
  message: state.services.message, 
  isAuthenticated: state.auth.isAuthenticated, 
  user: state.auth.user,  
  errorZapisatEl: state.services.errorZapisatEl,
  isFetchingZapisatEl: state.services.isFetching.zapisatElement,
  masterPassword: state.services.masterPassword,
});


const mapDispatchToProps = dispatch => bindActionCreators({
  onClickMenu,
  zapisatElement,
  deleteElement,
  closeMsg,
  getTabl,
  saveUser,
  logout,
  priglUser,
  newError,
  onClickGroupMenu,
  setMasterPassword
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashBoardPage);
