import React from 'react';
import { Redirect } from 'react-router-dom';

import Login from './Login';
import Register from './Register';
import PodtverditEmail from './PodtverditEmail';
import VostanovlenieParolya  from './VostanovlenieParolya';
import CustomizedSnackbars from './CustomizedSnackbar';

class WelcomePage extends React.Component {
  state ={
    email: '',
    password: '',
    strRegister: false,
    strVosstanovlenie: false,
  }


  onEmailChange = (e) =>{
    this.setState({
        email: e.target.value
    })
  }  
  onPasswordChahge = (e) =>{
    this.setState({
      password: e.target.value
    })
  }
  onSigninSubmit = (e) =>{
    e.preventDefault();
    const { email, password } = this.state;

    this.props.login(email, password);
  }
  onSignupSubmit = (e) =>{
    e.preventDefault();

    let msg = '';
    if (this.state.strVosstanovlenie) {
      msg = 'Пожалуйста перейдите по ссылке, для восстановления пароля!';
    } else {
      msg = 'Пожалуйста, подтвердите регистрацию!';
    }

    const { email } = this.state;

    this.props.signup(email, msg);


  }

  onClickRegistracia = (e) =>{
    this.setState({
      strRegister: true,
      strVosstanovlenie: false
    })


  }
  onClickLogin = (e) =>{
    this.setState({
      strRegister: false,
      strVosstanovlenie: false
    })
  }
  onClickVostanovlenie = (e) =>{
    this.setState({
      strRegister: false,
      strVosstanovlenie: true
    })
  }


  render() {

    if (this.props.isAuthenticated){
      return (
        <Redirect to="/dashboard" />
      )  
    }    
    if (this.props.podtverditEmail) {
      return <PodtverditEmail 
        strVosstanovlenie = {this.state.strVosstanovlenie}       
      />;
    }
    let osnPanel;
    if (this.state.strRegister) {
      osnPanel = <Register 
        onSigninSubmit={this.onSignupSubmit} 
        onEmailChange={this.onEmailChange}
        email={this.state.email}
        onClickLogin={this.onClickLogin}
        onClickVostanovlenie={this.onClickVostanovlenie}
      />;
    } else if (this.state.strVosstanovlenie) {
      osnPanel = <VostanovlenieParolya 
        onSigninSubmit={this.onSignupSubmit} 
        onEmailChange={this.onEmailChange}
        email={this.state.email}
        onClickLogin={this.onClickLogin}
        onClickRegistracia={this.onClickRegistracia}

      />;


    } else {
      osnPanel = <Login 
        onSigninSubmit={this.onSigninSubmit} 
        onEmailChange={this.onEmailChange}
        email={this.state.email}
        password={this.state.password}
        onPasswordChahge={this.onPasswordChahge}
        onClickRegistracia={this.onClickRegistracia}
        onClickVostanovlenie={this.onClickVostanovlenie}
    />
    }


    return(
      <React.Fragment>
        {osnPanel}

        <CustomizedSnackbars 
          msg={this.props.message.msg}
          openMsg = {this.props.message.openMsg}
          variantMsg={this.props.message.variantMsg}
          closeMsg = {this.props.closeMsg}
        />
      </React.Fragment>

    )
  }
}

export default WelcomePage;
