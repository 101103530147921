import React from 'react';

import CellMeasurerExample from './CellMeasurerExample';

export default function TabPanelEl({nameTabl, isAuthenticated, getTabl, ...props}) {

  return(
    <React.Fragment>

      <div className="panel-tag">
        <h5>Для отправки приглашения пользователю вступить в группу, введите e-mail пользователя и нажмите кнопку пригласить!</h5>
      </div>
      
        <div className="form-group row">
          <label className="form-label col-sm-3 col-form-label text-left text-sm-right">
            e-mail:
          </label>
          <div className="col-lg-6">
            <input className="form-control" name="emailPrigl" onChange={props.onChange} value={props.data.emailPrigl}/>
          </div>
          <div className="col-lg-3 el1">
            <button className="btn btn-primary b5" onClick={props.priglUser}>Пригласить</button>
          </div>
          
        </div>
       
      <br/>

      <CellMeasurerExample 
        data = {props.data[nameTabl]}
        nameTabl = {nameTabl}

        height = {200}

        deleteElement = {props.deleteElement}
        onClickEditElement = {props.onClickEditElement}
        onChange1 = {props.onChange1}
      />

    </React.Fragment>
  )
}  
