import React from 'react';

export default function Group(props) {

  const onClickEl = (e) => {

    props.onClickGroupMenuSet(e.target.id);
  }

  let el = null;
  let el1=null;

  if (props.tabl) {


    if (props.nastroiki && props.nastroiki.nastroiki && props.nastroiki.nastroiki.idGroup && props.nastroiki.nastroiki.idGroup.length > 0) {

      el = props.nastroiki.nastroiki.idGroup.map((item)=>{
        const name = props.tabl.usergroups.data.find(item1 => item1._id == item).name;

        return(
          <button className="dropdown-item" id={item} key={item} onClick={onClickEl}>{name}</button>
        )
      })
    } else {
      el1 = <button className="dropdown-item" id='' onClick={onClickEl}>Личные данные </button>
      el = props.tabl.usergroups.data.map((item)=>{
        return(
          <button className="dropdown-item" id={item._id} key={item._id} onClick={onClickEl}>{item.name}</button>        
        )
      })
    }


  }

  let clsshow = "btn-group";
  if (props.openGroupMenu) {
    clsshow = clsshow + ' show';
  }

  if (props.tekMenuGroup===null) {
    return null;

  }

  return(
    <div className={clsshow}>
      <button className="btn btn-primary dropdown-toggle bg-trans-gradient waves-effect waves-themed" onClick={props.onClickGroupMenu} id={props.tekMenuGroup.id}>{props.tekMenuGroup.name}</button>

      <div className="dropdown-menu dropdown-menu-animated show">
        {el1}
        {/* <button className="dropdown-item" id='' onClick={onClickEl}>Личные данные </button> */}
        {el}
      </div>
    </div>

  )
}
