import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { signup, login, closeMsg } from '../actions';
import WelcomePage from '../components/WelcomePage';

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  message: state.services.message,
  podtverditEmail: state.auth.podtverditEmail,
  
});


const mapDispatchToProps = dispatch => bindActionCreators({
  signup,
  login, 
  closeMsg,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomePage);
