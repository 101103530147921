import React, { useEffect, useState } from 'react';

import CellMeasurerExample from './CellMeasurerExample';
import CircularDeterminate from './CircularDeterminate';

export default function TabPanel1({nameTabl, isAuthenticated, getTabl, ...props}) {
  const [search, setSearch] = useState('');
  useEffect(() => {

    if (!props.tablsuccess[nameTabl]) {
      getTabl(nameTabl); 
    }

    props.data[nameTabl].kolonki.forEach(element => {
      if (element.type === 'ssilka') {
        if (element.typeSsilka && !props.tablsuccess[element.typeSsilka]) {
          getTabl(element.typeSsilka)
        }
      }
    });

    if (nameTabl==='obhodnoiList') {
      if (!props.tablsuccess['zapuskayushie']) {
        getTabl('zapuskayushie');
      }
    }

  },[nameTabl, getTabl, props.tablsuccess, props.data]);  

  if (!props.tablsuccess[nameTabl]) {
    return(
      <CircularDeterminate />
    )
  }

  let data = Object.assign({}, props.data[nameTabl]);
  data.data = props.data[nameTabl].data.slice();

  if (search !=='') {
    data.data = data.data.filter((item)=> item.name.toLowerCase().indexOf(search) >= 0)
  }



  let massivKnopok = [];


  if (!props.data[nameTabl].noAdd) {
    massivKnopok.push(
      <button key = 'add' className="btn btn-success btn-sm mr-1" onClick={props.onClickAdd}>
        <span>
          <i className="fal fa-plus mr-1" />
        </span>
        Добавить
      </button>
    )
  }

  massivKnopok.push(
    <button key='obonovit' className="btn btn-primary btn-sm mr-1" onClick={()=>getTabl(nameTabl)}>
      <span>
        <i className="fal fa-sync mr-1" />
      </span>
      Обновить
    </button>    
  )




  return(
    <React.Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row mb-3">
              <div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-start">
                <div className="dt-buttons">
                  {massivKnopok}
                  {/* <button className="btn btn-success btn-sm mr-1" onClick={props.onClickAdd}>
                    <span>
                      <i className="fal fa-plus mr-1" />
                    </span>
                    Добавить
                  </button>
                  <button className="btn btn-primary btn-sm mr-1" onClick={()=>getTabl(nameTabl)}>
                    <span>
                      <i className="fal fa-sync mr-1" />
                    </span>
                    Обновить
                  </button> */}
                </div>  


                <div className="panel-toolbar ml-2">
                  <div className="d-flex position-relative ml-auto">
                    <i className="fal fa-search position-absolute pos-left fs-lg px-2 py-1 mt-1 fs-xs" />
                    <input type="text" className="form-control form-control-sm pl-5" placeholder="Найти" value={search} onChange={(event)=>setSearch(event.target.value)}/>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>  
      </div>

      <CellMeasurerExample 
        height = {600}
        // data = {props.data[nameTabl]}
        data = {data}
        deleteElement = {props.deleteElement}
        onClickEditElement = {props.onClickEditElement}
        dataAll = {props}
      />

    </React.Fragment>
  )
}  
