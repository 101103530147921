import React from 'react';

export default function CircularDeterminate() {

  return(
    <div className="spinner-border text-primary">
      <span className="sr-only">лоадинг</span>

    </div>
  )
}
