import React from 'react';
import CircularDeterminate from './CircularDeterminate';


const LeftMenu = ({selectedIndex, leftMenu, onClickMenu}) =>{

  if (!leftMenu.length) {
    return(
      <CircularDeterminate />
    )
  }
  const elements = leftMenu.map((item, key)=>{

    let classLi = "";
    if (selectedIndex === item.id) {
      classLi="active";
    }
    
    return(
      <li key={item.id} className={classLi}>
        <a href="#"  title={item.name} onClick={() => onClickMenu(item.id)} data-filter-tags="theme settings">

          <i className={`far ${item.icon1}`}></i>
          
          <span className="nav-link-text" data-i18n="nav.theme_settings">{item.name}</span>

        </a>

      </li>    
    )
  })

  return(

    <ul id="js-nav-menu" className="nav-menu">
      {elements}
    </ul>       
  )
}

export default LeftMenu;
