import * as React from 'react';
import {AutoSizer} from 'react-virtualized';
import clsx from 'clsx';

import DynamicWidthMultiGrid from './DynamicWidthMultiGrid';

export default class CellMeasurerExample extends React.PureComponent {

  render() {

    return (


        <AutoSizer disableHeight>
          {({width}) => (
            <div style={{width}}>
              <DynamicWidthMultiGrid
                getClassName={getClassName}
                data={this.props.data}
                width={width}
                deleteElement = {this.props.deleteElement}
                editElement = {this.props.onClickEditElement}
                onChange1 = {this.props.onChange1}
                nameTabl = {this.props.nameTabl}
                height = {this.props.height}
                dataAll = {this.props.dataAll}
              />
            </div>
          )}
        </AutoSizer>

    );
  }

}

function getClassName({columnIndex, rowIndex}) {
  const rowClass = rowIndex % 2 === 0 ? "evenRow" : "oddRow";
  const colorClass = rowIndex === 0 ? "colorClass" : "";

  return clsx(rowClass, colorClass, "cell1");

}



