import React from 'react';

import LeftMenu from './LeftMenu';
import DashboardOblast from './DashboardOblast';
import Group from './Group';


export default function Dashboard(props) {
  const [openGroupMenu, setOpenGroupMenu] = React.useState(false);

  const onClickGroupMenu = (e) => {
    //if (e.target===e.currentTarget) {

    setOpenGroupMenu(!openGroupMenu);
    e.stopPropagation();
  }

  const onClick = (e)=>{
    setOpenGroupMenu(false);
  }

  const onClickToggle = (e) => {
    document.body.classList.add('mobile-nav-on');
    e.stopPropagation();
  }

  const onClickContent = (e) =>{
    document.body.classList.remove('mobile-nav-on');
  }

  const onClickMenu = (id) => {
    document.body.classList.remove('mobile-nav-on');
    props.onClickMenu(id);
  }


  return(


    <div className="page-wrapper" onClick={onClick}>
      <div className="page-inner">
        <aside className="page-sidebar">
          <div className="page-logo">
            <img src="image/logo.png" alt="Д.В. Поляков" aria-roledescription="logo" />  
            <span className="page-logo-text mr-1">Деловые программы</span>
          </div>        
          <nav id="js-primary-nav" className="primary-nav" role="navigation">
            <div className="info-card">
              {/* <img src="image/avatars/avatar-admin.png" className="profile-image rounded-circle" alt="Д.В. Поляков" />
              <div className="info-card-text">
                <a href="https://polyakovdmitriy.ru/" rel="noopener noreferrer" target="_blank" className="d-flex align-items-center text-white logo1">
                  <span className="text-truncate text-truncate-sm d-inline-block">
                    Д.В. Поляков
                  </span>
                </a>
                <span className="d-inline-block text-truncate text-truncate-sm">Санкт-Петербург</span>
              </div> */}
              <img src="image/card-backgrounds/cover-2-lg.png" className="cover" alt="cover" />

            </div>

            <LeftMenu
              selectedIndex = {props.menuIndex}
              leftMenu = {props.leftMenu}
              onClickMenu = {onClickMenu}
            />
          </nav>
        </aside>
        <div className="page-content-wrapper" onClick={onClickContent}>
          <header className="page-header" role="banner">

            <div className="hidden-lg-up">
              <button className="header-btn btn press-scale-down" data-action="toggle" data-class="mobile-nav-on" onClick={onClickToggle}>
                <i className="ni fa-bars"></i>
              </button>
            </div>

            <Group 
              openGroupMenu = {openGroupMenu}
              onClickGroupMenu = {onClickGroupMenu}
              onClickGroupMenuSet = {props.onClickGroupMenuSet}
              tekMenuGroup = {props.tekMenuGroup}
              tabl = {props.tabl}
              nastroiki = {props.nastroiki}
            />

            <div className="ml-auto d-flex">
              <div className="hidden-md-down">
                <button className="header-icon b3" data-toggle="modal" data-target=".js-modal-settings" onClick={props.onClickLogout}>
                  <i className="fal fa-sign-out"></i>
                </button>
              </div>

            </div>
          </header>

          <main id="js-page-content" role="main" className="page-content">

            <DashboardOblast 
              leftMenu = {props.leftMenu}
              menuIndex = {props.menuIndex}
              tabl = {props.tabl}
              tablsuccess = {props.tablsuccess}
              zapisatElement = {props.zapisatElement}
              deleteElement = {props.deleteElement}
              getTabl = {props.getTabl}
              errorZapisatEl = {props.errorZapisatEl}
              isFetchingZapisatEl = {props.isFetchingZapisatEl}
              isAuthenticated = {props.isAuthenticated}   
              user = {props.user} 
              onSaveSubmit = {props.onSaveSubmit} 
              priglUser = {props.priglUser}    
              newError = {props.newError}    
              setMasterPassword = {props.setMasterPassword}
              masterPassword = {props.masterPassword} 
              tekMenuGroup = {props.tekMenuGroup}     
            />
            
          </main>

          <footer className="page-footer" role="contentinfo">
            <div className="d-flex align-items-center flex-1 text-muted">
              <span className="hidden-md-down fw-700">2019 © Поляков Дмитрий&nbsp;<a href='https://polyakovdmitriy.ru/' rel="noopener noreferrer" className='text-primary fw-500' title='polyakovdmitriy.ru' target='_blank'>polyakovdmitriy.ru</a></span>
            </div>

          </footer>
        </div>
      </div>

    </div>
  )
}
