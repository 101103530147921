import React, {useEffect} from 'react';

import KalendarStroka from './KalendarStroka';
import KalendarPodr from './KalendarPodr';
import CircularDeterminate from './CircularDeterminate';
import {mesPropis} from '../utils/format-data';

export default function Kalendar({propPanel, isAuthenticated, getTabl, ...props}) {
 
  const [tekDate, setTekDate] = React.useState(new Date());
  const [openPodr, setOpenPodr] = React.useState(false);
  const [koordinatiPodr, setKoordinatiPodr] = React.useState({
    top: '100px',
    left: '100px',
  });
  const [dataPodr, setDataPodr] = React.useState(null);

  useEffect(() => {


    if (!props.tablsuccess[propPanel.nameTabl]) {
      getTabl(propPanel.nameTabl);
    }
    
  }, [propPanel.nameTabl, getTabl, props.tablsuccess]); 
  
  const onClickClosePodr = () => {
    setOpenPodr(false);
  }

  const onClickKalendar = (e) => {

    if (e.target.name==='kalendarPodr') {
      const top = e.target.getBoundingClientRect().y - e.currentTarget.getBoundingClientRect().y;
      const left = e.target.getBoundingClientRect().x - e.currentTarget.getBoundingClientRect().x;

      setOpenPodr(true);   
      setKoordinatiPodr({
        top: `${top}px`,
        left: `${left}px`,
      });
      setDataPodr(e.target.id);  
    } 

  }


  const onClickLeft = () => {
    let newDate = new Date(tekDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setTekDate(newDate);
  }

  const onClickRight = () => {
    let newDate1 = new Date(tekDate);
    newDate1.setMonth(newDate1.getMonth() + 1);
    setTekDate(newDate1);    
  }



  const elements = () => {

    let dataNed = new Date(tekDate);
    dataNed.setDate(1);

    while (dataNed.getDay() !== 1) {
      dataNed.setDate(dataNed.getDate()-1);
    }

    let masEl = [];
    for (let i = 0; i < 6; i++) { 
      

      masEl.push(
        <KalendarStroka 
          key={`${propPanel.id} ${i}`}
          propPanel = {propPanel}
          data = {props.data}
          dataNed = {dataNed}
          tekDate = {tekDate}
          nomerStr = {i}
          onClickEditElement = {props.onClickEditElement}
        />
      );


    } 
    return(
      <React.Fragment>
        {masEl}
      </React.Fragment>
    )   
  }

  const datah2 = mesPropis(tekDate) + ' ' + tekDate.getFullYear();


  if (!props.tablsuccess[propPanel.nameTabl]) {
    return(
      <CircularDeterminate />
    )
  }

  return(
    <div className="fc fc-ltr fc-bootstrap">      
      <div className="fc-toolbar fc-header-toolbar k1">
        <div className="fc-left">
          <div className="btn-group">
            <button type="button" className="fc-prev-button btn btn-default waves-effect waves-themed" onClick={onClickLeft}>
              <i className="fas fa-chevron-left" />
            </button>
            <button type="button" className="fc-next-button btn btn-default waves-effect waves-themed" onClick={onClickRight}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>         
        </div>
        <div className="fc-center">
          <h2>{datah2}</h2>
        </div>
        <div className="fc-right">
          <div className="btn-group">

            <button className="btn btn-success btn-sm mr-1" onClick={props.onClickAdd}>
              <span>
                <i className="fal fa-plus mr-1" />
              </span>
              Добавить
            </button>
            <button className="btn btn-primary btn-sm mr-1" onClick={()=>getTabl(propPanel.nameTabl)}>
              <span>
                <i className="fal fa-sync mr-1" />
              </span>
              Обновить
            </button>

          </div>
        </div>
      </div>
      <div className="fc-view-container">
        <div className="fc-view fc-dayGridMonth-view fc-dayGrid-view" onClick={onClickKalendar}>
          <table className="table-bordered">
            <thead className="fc-head">
              <tr>
                <td className="fc-head-container">
                  <div className="fc-row table-bordered">
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th className="fc-day-header  fc-sun">
                            <span>Понедельник</span>
                          </th>
                          <th className="fc-day-header  fc-sun">
                            <span>Вторник</span>
                          </th>
                          <th className="fc-day-header  fc-sun">
                            <span>Среда</span>
                          </th>
                          <th className="fc-day-header  fc-sun">
                            <span>Четверг</span>
                          </th>
                          <th className="fc-day-header  fc-sun">
                            <span>Пятница</span>
                          </th>
                          <th className="fc-day-header  fc-sun">
                            <span>Суббота</span>
                          </th>
                          <th className="fc-day-header  fc-sun">
                            <span>Воскресенье</span>
                          </th>                         
                        </tr>
                      </thead>
                    </table>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody className="fc-body">
              <tr>
                <td>
                  <div className="fc-scroller fc-day-grid-container">
                    <div className="fc-day-grid">




                      {elements()}


                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <KalendarPodr
            open = {openPodr}
            koordinati = {koordinatiPodr}
            onClickClosePodr = {onClickClosePodr}
            dataPodr = {dataPodr}
            propPanel = {propPanel}
            data = {props.data} 
            onClickEditElement = {props.onClickEditElement}           
          />


        </div>
      </div>
    </div>


  )
}
