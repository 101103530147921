import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

import WelcomePage from '../containers/WelcomePage';
import ZadatParol from '../containers/ZadatParol';
import PrivateRoute from '../containers/PrivateRoute';
import DashBoardPage from '../containers/DashBoardPage';

function App() {

  let strToken = window.location.search;
  
  if (strToken.slice(0, 7) === '?token=') {
    let token = strToken.slice(7);
    if (token) {
      localStorage.setItem('token', token);
      window.location.search = '';
    }
  }  
  return (
    <Router>
      <Switch>
        <Route exact path='/(welcome)?' component={WelcomePage} />
        <Route path='/emailpodtverdit' component={ZadatParol} />

        <PrivateRoute path='/dashboard' component={DashBoardPage} />        
        <Redirect to='/' />
      </Switch>
    </Router>
  );
}

export default App;
