import React, { useEffect } from 'react';

import Kalendar from './Kalendar';
import TablPanel1 from './TablPanel1';
import EditElementDialog from './EditElementDialog';
import scrypt from 'scrypt-js';
import buffer from 'scrypt-js/thirdparty/buffer';
import { toBytes, toBytesHex, ModeOfOperationCTR, Counter, fromBytes, fromBytesHex } from '../utils/aes';

import {formatData} from '../utils/format-data';

export default function EditObertka(props) {

  // const [secretPass, setSecretPass] = React.useState(null);
  // const [secretData, setSecretData] = React.useState(true);
  const [open, setOpen] = React.useState('');
  const [dataForm, setDataForm] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);

  useEffect(()=>{

      if (open === 'show dop1') {
        if (dataForm._id) {
  
          let data1 = props.data[props.propPanel.nameTabl];
  
          let data2 = data1.data.find(item => item._id === dataForm._id);
      
          let dataF = {};
          Object.assign(dataF, data2);
  
      
          data1.kolonki.forEach(element => {
            if (element.type === 'data') {
              dataF[element.dataKey] = formatData(new Date(dataF[element.dataKey]));
            } else if (element.type === 'tabl') {
              let newOb1 = Object.assign({}, element.struk);
              newOb1.data = dataF[element.dataKey].slice();
              dataF[element.dataKey] = newOb1;
            
            } else if (element.type === 'tabluser') {
  
              let newOb = Object.assign({}, element.struk);
              newOb.data = dataF[element.dataKey].slice();
              dataF[element.dataKey] = newOb;
      
              dataF.emailPrigl = '';
            }
            
          });
      
          setDataForm(dataF); 
  
  
        }
  
      }
  
    
  }, [props.data, open, props.propPanel.nameTabl]);

  useEffect(() => {

    if (isFetching && open === 'show dop1') {
      if (!props.isFetchingZapisatEl) {
        if (!props.errorZapisatEl.errorZapisatEl) {
          setOpen('');
        }
        setIsFetching(false);

      }

  
    }

  },[isFetching, props.errorZapisatEl.errorZapisatEl, props.isFetchingZapisatEl, open, props]);  


  const onChange =(event) => {

    
    let secretF = null;

    if (event.target.name.length > 9) {

      if (event.target.name.slice(-8)==='_rassifr') {
        secretF = event.target.name.slice(0, event.target.name.length - 8);

      }
      //if (event.target.name.slice(-9))
    }

    let dataF = Object.assign({}, dataForm);




    dataF[event.target.name] = event.target.value;

    if (secretF !== null) {
      const password =  new buffer.SlowBuffer(props.masterPassword.normalize('NFKC')); 
      const salt =  new buffer.SlowBuffer("someSalt".normalize('NFKC')); 
      const N = 1024, r = 8, p = 1;
      const dkLen = 16;

      const key =  scrypt.syncScrypt(password, salt, N, r, p, dkLen);

      let textBytes = toBytes(event.target.value);

      let aesCtr = new ModeOfOperationCTR(key, new Counter(5));
      let encryptedBytes = aesCtr.encrypt(textBytes);
      
      let encryptedHex = fromBytesHex(encryptedBytes);

      dataF[secretF] = encryptedHex;
    }

    setDataForm(dataF);
  }

  const onClickBool= (event) => {
    let dataF = Object.assign({}, dataForm);
    if (dataF[event.target.name]) {
      dataF[event.target.name] = false; 
    } else {
      dataF[event.target.name] = true;      
    }
    setDataForm(dataF);  
  }

  const onChange1 = (rowIndex, columnIndex, nameTabl, event) => {




    let dataF2=dataForm[nameTabl].data.slice(rowIndex, rowIndex+1);
    let dataF3 = Object.assign({}, dataF2[0]);


    if (dataF3[dataForm[nameTabl].kolonki[columnIndex].type] === 'bool') {
      dataF3[dataForm[nameTabl].kolonki[columnIndex].dataKey] = !dataF3[dataForm[nameTabl].kolonki[columnIndex].dataKey];

    } else {
      dataF3[dataForm[nameTabl].kolonki[columnIndex].dataKey] = event.target.value;

    }

    // dataF3[dataForm[nameTabl].kolonki[columnIndex].dataKey] = !dataF3[dataForm[nameTabl].kolonki[columnIndex].dataKey];
    let dataF4 =[...dataForm[nameTabl].data.slice(0, rowIndex), dataF3, ...dataForm[nameTabl].data.slice(rowIndex+1)];

    let dataF1= {...dataForm};
    dataF1[nameTabl].data = dataF4;

    setDataForm(dataF1);

  }

  const handleClickOpenIzm = (id, e) => {
    

    let data1 = props.data[props.propPanel.nameTabl];

    let data2 = data1.data.find(item => item._id === id);

    let dataF = {};
    Object.assign(dataF, data2);

    let isSecret = false

    data1.kolonki.forEach(element => {
      if (element.type === 'data') {
        dataF[element.dataKey] = formatData(new Date(dataF[element.dataKey]));
      } else if (element.type === 'tabluser') {
        let newOb = Object.assign({}, element.struk);
        newOb.data = dataF[element.dataKey].slice();
        dataF[element.dataKey] = newOb;

        dataF.emailPrigl = '';
      } else if (element.type === 'tabl') {

        let newOb1 = Object.assign({}, element.struk);
        newOb1.data = dataF[element.dataKey].slice();
        dataF[element.dataKey] = newOb1;
      
      } else if (element.type === 'secret') {
        isSecret = true;
        const password =  new buffer.SlowBuffer(props.masterPassword.normalize('NFKC')); 
        const salt =  new buffer.SlowBuffer("someSalt".normalize('NFKC')); 
        const N = 1024, r = 8, p = 1;
        const dkLen = 16;
  
        const key =  scrypt.syncScrypt(password, salt, N, r, p, dkLen);
        let encryptedBytes1 = toBytesHex(dataF[element.dataKey]);  
        let aesCtr1 = new ModeOfOperationCTR(key, new Counter(5));
        let decryptedBytes = aesCtr1.decrypt(encryptedBytes1);
        let decryptedText = fromBytes(decryptedBytes);                      
        dataF[element.dataKey+'_rassifr'] = decryptedText;
      }
      
    });

    if (isSecret && props.masterPassword ==='') {
      props.newError('Не задан мастер пароль')
      return null;
    }

    setDataForm(dataF);
    setOpen('show dop1');
  };  

  const handleClickOpen = () => {


    if (!props.data[props.propPanel.nameTabl]) {
      return null
    }    


    
    let isSecret = false
    let dataF = {};

    props.data[props.propPanel.nameTabl].kolonki.forEach((item, key)=>{
      if (item.type === 'data') {
        dataF[item.dataKey] = formatData(new Date());
      } else if (item.type === 'tabluser') {
        let newObj = Object.assign({}, item.struk);

        newObj.data = [{
          email: props.user.email,
          admin: true,
          firstName: props.user.firstName,
          lastName: props.user.lastName,
          priglPrinato: true,
          dostup: true,             
        }];

        dataF[item.dataKey] = newObj;

        dataF.emailPrigl = '';
 
        
      } else if (item.type === 'tabl') {
        let newObj1 = Object.assign({}, item.struk);
        newObj1.data = [];

        dataF[item.dataKey] = newObj1;


      } else if (item.type ==='secret'){
        isSecret = true;
        dataF[item.dataKey] = '';
        let keyF = item.dataKey + '_rassifr';
        dataF[keyF] = '';
      } else {
        
        
        dataF[item.dataKey] = '';
      }

    })

    if (isSecret && props.masterPassword ==='') {
      props.newError('Не задан мастер пароль')
      return null;
    }

    if (props.data[props.propPanel.nameTabl].dataKey = 'obhodnoiList') {

      dataF['zapustil'] = props.user.email;
      dataF['status'] = 'Не запущен';

      const marshrut = props.data['zapuskayushie'].data.find(item => item.user ===props.user.email);
      if (marshrut) {
        dataF['marshrut'] = marshrut.marshrut;
      }


    }

    setDataForm(dataF); 
 
    setOpen('show dop1');
  }

  const handleClose = () => {
    setOpen('');
  };

  const zapisatElement = (nameKey, sogl) => {

    let dataF = {};
    props.data[props.propPanel.nameTabl].kolonki.forEach((item, key)=>{

      if (item.type === 'data') {
        let newData = new Date(dataForm[item.dataKey].slice(0,4), (dataForm[item.dataKey].slice(5,7) - 1), dataForm[item.dataKey].slice(8, 10), dataForm[item.dataKey].slice(11, 13), dataForm[item.dataKey].slice(14, 16));

        dataF[item.dataKey] = newData;
      } else if (item.type === 'tabluser'){
        dataF[item.dataKey] = dataForm[item.dataKey].data.slice();
      } else if (item.type === 'tabl'){
        dataF[item.dataKey] = dataForm[item.dataKey].data.slice();        
      } else {
        dataF[item.dataKey] = dataForm[item.dataKey];
      }

    })  
    if (dataForm._id) {
      dataF._id = dataForm._id;
    }

    if (nameKey === 'soglasovat') {
      dataF.dataSoglasovania = new Date();
      if (sogl === 'soglasovano') {
        dataF.status = 'Согласовано'
      } else {
        dataF.status = 'Не согласовано'
      }
    }

    if (nameKey === 'obhodnoiList') {

      if (!dataF.sotrudnik) {
        props.newError('Не заполнен сотрудник!');
        return null;
      }

      if (!dataF.marshrut) {
        props.newError('Не заполнен маршрут!');
        return null;
      }


      props.zapisatElement(nameKey, dataF);
      setIsFetching(true);

    } else {
      props.zapisatElement(nameKey, dataF);
 
      setIsFetching(true);
    }



  }  

  const onClickAddTabl = (e, nameTabl) => {
    e.preventDefault();
    const dataF3 = Object.assign({}, dataForm);
    dataF3[nameTabl].data.push({user: ''});



    setDataForm(dataF3);
  }

  const priglUser = (e) => {

    e.preventDefault();

    if (!dataForm._id) {
      props.newError('Ошибка! Группу в начале нужно записать!')
    } else if (!props.user.lastName) {
      props.newError('Ошибка! Нужно в настройках ввести Имя и Фамилию, а то не понятно от кого приглашение!')
    } else if (!props.user.firstName) {
      props.newError('Ошибка! Нужно в настройках ввести Имя и Фамилию, а то не понятно от кого приглашение!')
    } else {
      props.priglUser(dataForm.emailPrigl, dataForm._id)
     
    }

  }


  let el1 = [];
  if (props.propPanel.type==='kalendar') {
    el1.push(
      <Kalendar
        key = {props.propPanel.id}
        data = {props.data}
        tablsuccess = {props.tablsuccess}
        getTabl = {props.getTabl}
        isAuthenticated = {props.isAuthenticated}
        propPanel = {props.propPanel}
        onClickEditElement = {handleClickOpenIzm}
        onClickAdd = {handleClickOpen}
      />      
    ) 
  } else if (props.propPanel.type==='tabl') {
    el1.push(
      <TablPanel1 
        key = {props.propPanel.id}
        data = {props.data}
        tablsuccess = {props.tablsuccess}
        tekMenuGroup = {props.tekMenuGroup}

        deleteElement = {props.deleteElement}
        getTabl = {props.getTabl}
        isAuthenticated = {props.isAuthenticated} 
        nameTabl ={props.propPanel.nameTabl}
        onClickEditElement = {handleClickOpenIzm}
        onClickAdd = {handleClickOpen}            
      /> 
    )
  }


  return(
    <React.Fragment>
      {el1}
      <EditElementDialog 
        open = {open}
        dataF = {props.data[props.propPanel.nameTabl]}
        
        onClickCloseElement = {handleClose}

        dataForm = {dataForm}
        zapisatElement = {zapisatElement}
        onChange = {onChange}
        onChange1 = {onChange1}
        onClickBool = {onClickBool}

        getTabl = {props.getTabl}
        data ={props.data}
        handleClickOpen={handleClickOpen}
        priglUser={priglUser}
        onClickAddTabl={onClickAddTabl}

        user = {props.user}
        masterPassword = {props.masterPassword} 
        tekMenuGroup = {props.tekMenuGroup}
      />      
    </React.Fragment>
  )
}
