export const SIGNUP_REQUEST = Symbol('auth/SIGNUP_REQUEST');
export const SIGNUP_SUCCESS = Symbol('auth/SIGNUP_SUCCESS');
export const SIGNUP_FAILURE = Symbol('auth/SIGNUP_FAILURE');

export const PAROL_REQUEST = Symbol('auth/PAROL_REQUEST');
export const PAROL_SUCCESS = Symbol('auth/PAROL_SUCCESS');
export const PAROL_FAILURE = Symbol('auth/PAROL_FAILURE');

export const LOGIN_REQUEST = Symbol('auth/LOGIN_REQUEST');
export const LOGIN_SUCCESS = Symbol('auth/LOGIN_SUCCESS');
export const LOGIN_FAILURE = Symbol('auth/LOGIN_FAILURE');

export const LOGOUT_SUCCESS = Symbol('auth/LOGOUT_SUCCESS');

export const RECIEVE_AUTH_REQUEST = Symbol('auth/RECIEVE_AUTH_REQUEST');
export const RECIEVE_AUTH_SUCCESS = Symbol('auth/RECIEVE_AUTH_SUCCESS');
export const RECIEVE_AUTH_FAILURE = Symbol('auth/RECIEVE_AUTH_FAILURE');

export const SAVE_USER_REQUEST = Symbol('auth/SAVE_USER_REQUEST');
export const SAVE_USER_SUCCESS = Symbol('auth/SAVE_USER_SUCCESS');
export const SAVE_USER_FAILURE = Symbol('auth/SAVE_USER_FAILURE');

export const SOEDINITTELEGRAM_REQUEST = Symbol('auth/SOEDINITTELEGRAM_REQUEST');
export const SOEDINITTELEGRAM_SUCCESS = Symbol('auth/SOEDINITTELEGRAM_SUCCESS');
export const SOEDINITTELEGRAM_FAILURE = Symbol('auth/SOEDINITTELEGRAM_FAILURE');

export const USERGROUPPRIGLPRINATO_REQUEST = Symbol('auth/USERGROUPPRIGLPRINATO_REQUEST');
export const USERGROUPPRIGLPRINATO_SUCCESS = Symbol('auth/USERGROUPPRIGLPRINATO_SUCCESS');
export const USERGROUPPRIGLPRINATO_FAILURE = Symbol('auth/USERGROUPPRIGLPRINATO_FAILURE');
