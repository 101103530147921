import React from 'react';

import KalendarDopResurs from './KalendarDopResurs';

export default function KalendarStroka(props) {

  const el1 = () => {
    let masEl=[];
    let denDate = new Date(props.dataNed);
    denDate.setDate(denDate.getDate() + 7 * props.nomerStr);

    let denStr = '';
    for (let i = 0; i < 7; i++) {
      
      if (denDate.getMonth() !== props.tekDate.getMonth()) {
        denStr = '';
      } else {

        denStr = denDate.getDate();
      }
      masEl.push(
        <td key={`k2${props.propPanel.id}${i}`} className="fc-day-top fc-sun fc-past">
          <span className="fc-day-number">{denStr}</span>
        </td>
      ); 
      denDate.setDate(denDate.getDate()+1);
    }

    return(
      <React.Fragment>
        {masEl}
      </React.Fragment>
    ) 
  }

  const el2 = () => {
    let masEl=[];
    let denDate = new Date(props.dataNed);
    denDate.setDate(denDate.getDate() + 7 * props.nomerStr);

    let denStr = '';
    for (let i = 0; i < 7; i++) {
      
      if (denDate.getMonth() !== props.tekDate.getMonth()) {
        denStr = 'fc-day fc-past fc-other-month';
      } else if (denDate.getDate()===new Date().getDate() && denDate.getMonth()===new Date().getMonth() && denDate.getFullYear()===new Date().getFullYear()){
        denStr = 'fc-day fc-past alert-info';
      } else {
        denStr = 'fc-day fc-past';
      }
      masEl.push(
        <td key={`k1${props.propPanel.id}${i}`} className={denStr}></td>

      ); 
      denDate.setDate(denDate.getDate()+1);
    }

    return(
      <React.Fragment>
        {masEl}
      </React.Fragment>
    ) 
  }

  const el3 = () => {
    let masEl=[];

    let denDate = new Date(props.dataNed);
    denDate.setDate(denDate.getDate() + 7 * props.nomerStr);

    for (let i = 0; i < 7; i++) {
      
      if (denDate.getMonth() !== props.tekDate.getMonth()) {
        masEl.push(<td key={`k3${i}${props.propPanel.id}`}></td>);
      } else {

        let massivEl = props.data[props.propPanel.nameTabl].data.filter(function(elem) {

          let newDate = new Date(elem[props.propPanel.izmerenie]);

          return (newDate.getDate()===denDate.getDate() && newDate.getMonth()===denDate.getMonth() && newDate.getFullYear() === denDate.getFullYear());
        });
        let masEl1=[];

        if (massivEl.length > 0) {
          masEl1.push(
            <button key={`b1${massivEl[0]._id}`} id={massivEl[0]._id} onClick={(e)=> props.onClickEditElement(e.currentTarget.id, e)} className="b1 fc-day-grid-event fc-h-event fc-event fc-start fc-end border-warning bg-warning text-dark fc-draggable fc-resizable">
              <div className="b2 fc-content">
                <KalendarDopResurs 
                  propPanel={props.propPanel} 
                  element={massivEl[0]}
                />
                <span className="fc-title">{massivEl[0][props.propPanel.resurs]}</span>
              </div>
              <div className="fc-resizer fc-end-resizer"></div>
            </button> 
          );
        }

        if (massivEl.length > 1) {
          masEl1.push(
            <button key={`b2${massivEl[0]._id}`} id={massivEl[1]._id} onClick={(e)=> props.onClickEditElement(e.currentTarget.id, e)} className="b1 fc-day-grid-event fc-h-event fc-event fc-start fc-end border-warning bg-warning text-dark fc-draggable fc-resizable">
              <div className="b2 fc-content">
                <KalendarDopResurs 
                  propPanel={props.propPanel}
                  element={massivEl[1]}
                />
                <span className="fc-title">{massivEl[1][props.propPanel.resurs]}</span>
              </div>
              <div className="fc-resizer fc-end-resizer"></div>
            </button> 
          );
        }

        if (massivEl.length > 2) {
          masEl1.push(
            <button key={`b3${massivEl[0][props.propPanel.izmerenie]}`} className="b3 b4 fc-more" name="kalendarPodr" id={massivEl[0][props.propPanel.izmerenie]}>{' + ' + (massivEl.length - 2) + ' ещё'}</button>            
          )          
        }


        if (massivEl.length > 0) {
          masEl.push(
            <td key={`k5${i}${props.propPanel.id}`} className="fc-event-container">

              {masEl1}
          
            </td>          
          );
        } else {
          masEl.push(<td key={`k4${i}${props.propPanel.id}`}></td>);
        };

      }

      denDate.setDate(denDate.getDate()+1);
    }

    return(
      <React.Fragment>
        {masEl}
      </React.Fragment>
    ) 
  }  

  return(
    <div className="fc-row fc-week table-bordered fc-rigid k2">
    <div className="fc-bg">
      <table className="table-bordered">
        <tbody>
          <tr>
            {el2()}
          </tr>
        </tbody>
      </table>
    </div>
    <div className="fc-content-skeleton">
      <table>
        <thead>
          <tr>
            {el1()}                               
          </tr>
        </thead>
        <tbody>
          <tr>
            {el3()}
          </tr>
        </tbody>
      </table>
    </div>   
    </div>       

  )
}
