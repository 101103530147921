import * as types from '../constants';
import {combineReducers} from 'redux';

const initialState = {
  isFetching: {
    signup: false,
    zadatParol: false,
    login: false,
    recieveAuth: false,
    saveUser: false,
    zapisatElement: false,
    deleteElement: false,
    priglUser: false,
    soedinitTelegram: false,
    usergrouppriglprinato: false,
    getTabl: {},
  },
  message: {
    msg: '',
    openMsg: false,
    variantMsg: 'success',
  },
  menuIndex: {
    leftMenuIndex: 0,
  },
  errorZapisatEl: false,
  menu: {
    leftMenu: []
  },
  nastroiki: null,
  tekMenuGroup: null,
  masterPassword: '',

}

export const errorZapisatEl = (state=initialState.errorZapisatEl, action) =>{
  switch (action.type) {
    case types.ZAPISATELEMENT_SUCCESS:
      return {...state, errorZapisatEl: false};
    case types.ZAPISATELEMENT_FAILURE:
      return {...state, errorZapisatEl: true};  
    default:
      return state;  
  }
}

export const masterPassword = (state = initialState.masterPassword, action) => {
  switch (action.type) {
    case types.SET_MASTER_PASSWORD:
      return action.payload;
    default:
      return state;
  }
}

export const tekMenuGroup = (state = initialState.tekMenuGroup, action) => {
  switch (action.type) {
    case types.RECIEVE_AUTH_SUCCESS:

      if (state === null) {

        if (action.payload.nastroiki && action.payload.nastroiki.idGroup && action.payload.nastroiki.idGroup.length> 0) {
          const name = action.payload.tabl.usergroups.data.find(item=>item._id = action.payload.nastroiki.idGroup[0]).name;
          return {...state, id: action.payload.nastroiki.idGroup[0], name: name}
        } else {
          return {...state, id: '', name: 'Личные данные'}
        }
      } else {
        return {
          ...state,
        }
      }
    case types.SET_MENU_GROUP:
      return {...state, id: action.payload.id, name: action.payload.name};
    default:
      return state;
  }
}

export const menuIndex = (state = initialState.menuIndex, action) =>{
  switch (action.type) { 
    case types.SET_MENU_INDEX:
      return {...state, leftMenuIndex: action.payload};
    default:
      return state;
  }
}

export const menu = (state = initialState.menu, action) =>{

  switch (action.type) {
    case types.RECIEVE_AUTH_SUCCESS:
      return {
        ...state,
        leftMenu: action.payload.menu,
      }
    default:
      return state;
  }
}

export const nastroiki = (state = initialState.nastroiki, action) => {
  switch (action.type) {
    case types.RECIEVE_AUTH_SUCCESS:
      return {
        ...state,
        nastroiki: action.payload.nastroiki,
      }
    default:
      return state;
  }
}

export const isFetching = (state = initialState.isFetching, action) => {
  switch (action.type) {
    case types.SIGNUP_REQUEST:
      return {...state, signup: true};
    case types.PAROL_REQUEST:
      return {...state, zadatParol: true}
    case types.LOGIN_REQUEST:
      return {...state, login: true};
    case types.RECIEVE_AUTH_REQUEST:
      return {...state, recieveAuth: true};
    case types.SAVE_USER_REQUEST:
      return {...state, saveUser: true}; 
    case types.ZAPISATELEMENT_REQUEST:
      return {...state, zapisatElement: true}; 
    case types.GETTABL_REQUEST:
      let getTablNew = Object.assign({}, state.getTabl);
      getTablNew[action.nameTabl] = true;      
      return {...state, getTabl: getTablNew};
    case types.DELETEELEMENT_REQUEST:
      return {...state, deleteElement: true}
    case types.PRIGLUSER_REQUEST:
      return {...state, priglUser: true}
    case types.SOEDINITTELEGRAM_REQUEST:
      return {...state, soedinitTelegram: true}
    case types.USERGROUPPRIGLPRINATO_REQUEST:
      return {...state, usergrouppriglprinato: true}
      
    case types.SIGNUP_SUCCESS:
    case types.SIGNUP_FAILURE:
      return {...state, signup: false};
    case types.PAROL_SUCCESS:
    case types.PAROL_FAILURE:
      return {...state, zadatParol: false};    
    case types.LOGIN_SUCCESS:
    case types.LOGIN_FAILURE:      
      return {...state, login: false};
    case types.RECIEVE_AUTH_SUCCESS:
    case types.RECIEVE_AUTH_FAILURE:      
      return {...state, recieveAuth: false};
    case types.SAVE_USER_SUCCESS:
    case types.SAVE_USER_FAILURE:      
      return {...state, saveUser: false}; 
    case types.ZAPISATELEMENT_SUCCESS:
    case types.ZAPISATELEMENT_FAILURE:
      return {...state, zapisatElement: false}
    case types.GETTABL_SUCCESS:
    case types.GETTABL_FAILURE:
      let getTablNew1 = Object.assign({}, state.getTabl);
      getTablNew1[action.nameTabl] = false;
      return {...state, getTabl: getTablNew1}
    case types.DELETEELEMENT_SUCCESS:
    case types.DELETEELEMENT_FAILURE:
      return {...state, deleteElement: false}
    case types.PRIGLUSER_SUCCESS:
    case types.PRIGLUSER_FAILURE:
      return {...state, priglUser: false}
    case types.SOEDINITTELEGRAM_SUCCESS:
    case types.SOEDINITTELEGRAM_FAILURE:  
      return {...state, soedinitTelegram: false}
    case types.USERGROUPPRIGLPRINATO_SUCCESS:
    case types.USERGROUPPRIGLPRINATO_FAILURE:
      return {...state, usergrouppriglprinato: false}
    default:
      return state;
  }
}

export const message = (state = initialState.message, action) => {
  switch (action.type) {
    case types.SAVE_USER_SUCCESS:
    case types.ZAPISATELEMENT_SUCCESS:
    case types.DELETEELEMENT_SUCCESS:
    case types.PRIGLUSER_SUCCESS:
    case types.SOEDINITTELEGRAM_SUCCESS:
    case types.USERGROUPPRIGLPRINATO_SUCCESS:
      return {
        ...state,
        openMsg: true,
        msg: action.payload.message,
        variantMsg: 'success'
      };
    case types.SIGNUP_FAILURE:
    case types.LOGIN_FAILURE:
    case types.PAROL_FAILURE:  
    case types.ZAPISATELEMENT_FAILURE:
    case types.GETTABL_FAILURE:
    case types.DELETEELEMENT_FAILURE:
    case types.NEW_ERROR:
    case types.PRIGLUSER_FAILURE:
    case types.SOEDINITTELEGRAM_FAILURE:    
    case types.USERGROUPPRIGLPRINATO_FAILURE:     
      return {
        ...state,
        openMsg: true,
        msg: action.payload.message,
        variantMsg: 'error',
      };




    case types.CLOSE_MSG:
      return {
        ...state,
        openMsg: false,
        msg: '',
        variantMsg: 'success'
      }
    default:
      return state;  
  }
}

export default combineReducers({
  isFetching,
  message,
  menuIndex,
  menu,
  nastroiki,
  errorZapisatEl,
  tekMenuGroup,
  masterPassword,
})
