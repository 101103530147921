import React from 'react';


import NastroikiUser from './NastroikiUser';

import EditObertka from './EditObertka';
import Tab from './Tab';

export default function DashboardOblast(props) {

  switch(props.propPanel.type){
    case 'tab':
      return(
        <Tab 
          tabi = {props.propPanel.tabi}
          data = {props.data}
          tablsuccess = {props.tablsuccess}
          zapisatElement = {props.zapisatElement}
          deleteElement = {props.deleteElement}
          getTabl = {props.getTabl}
          errorZapisatEl = {props.errorZapisatEl}
          isAuthenticated = {props.isAuthenticated}   
          priglUser = {props.priglUser} 
          tekMenuGroup = {props.tekMenuGroup}
          user={props.user} 
          newError = {props.newError}      
        />
      );
    case 'kalendar':
      return(
        <EditObertka
        data = {props.data}
        tablsuccess = {props.tablsuccess}
        zapisatElement = {props.zapisatElement}
        deleteElement = {props.deleteElement}              
        getTabl = {props.getTabl}
        errorZapisatEl = {props.errorZapisatEl}
        isFetchingZapisatEl = {props.isFetchingZapisatEl}
        isAuthenticated = {props.isAuthenticated}
        propPanel = {props.propPanel}
        user={props.user}
        priglUser = {props.priglUser}
        newError = {props.newError}
      />         
      )
    
    case 'tabl':
      return(
        <EditObertka
        data = {props.data}
        tablsuccess = {props.tablsuccess}
        zapisatElement = {props.zapisatElement}
        deleteElement = {props.deleteElement}              
        getTabl = {props.getTabl}
        errorZapisatEl = {props.errorZapisatEl}
        isFetchingZapisatEl = {props.isFetchingZapisatEl}
        isAuthenticated = {props.isAuthenticated}
        propPanel = {props.propPanel}
        user={props.user}
        priglUser={props.priglUser}
        newError={props.newError}
        masterPassword = {props.masterPassword} 
        tekMenuGroup = {props.tekMenuGroup}
      />               
      )
    case 'nastroikiUser':
      return(
        <NastroikiUser
          user = {props.user} 
          onSaveSubmit = {props.onSaveSubmit}
       />        
       )
    case 'telegram':
      if (props.user.chatId) {
        return(
          <div className="custom-control custom-switch mr-2">
            <input type="checkbox" className="custom-control-input" checked readOnly/>
            <label className="custom-control-label">Уведомления в телеграмм включены!</label>
          </div>
          
        ) 
      } else {
          return (
            <React.Fragment>
              <div className="custom-control custom-switch mr-2">
                <input type="checkbox" className="custom-control-input" />
                <label className="custom-control-label">Включите уведомления в телеграм!</label>
              </div> 
              <br />            
              <div className="card border m-auto m-lg-0">
                <img src="image/bot_telegram.jpg" alt="Бот телеграм" className="card-img-top"/>
                <div className="card-body">
                  <h5 className="card-title">Уведомления в телеграм</h5>
                  <p className="card-text">Для включения уведомлений в телеграмм, нужно найти бота @DP_Todolist_bot и набрать в нем команду:</p>
                  <p className="card-text">/email Адрес электронной почты</p>
                  <a href="https://tlgg.ru/DP_TodoList_bot" rel="noopener noreferrer" className="btn btn-primary waves-effect waves-themed bt1" target="_blank">Открыть бота</a>
                  <a href="https://polyakovdmitriy.ru/poleznoe-po/" rel="noopener noreferrer" className="btn btn-primary waves-effect waves-themed" target="_blank">Инструкция</a>
                </div>
              </div>
            </React.Fragment>           
          )
        }
      
    default:
      return null
  }
}
