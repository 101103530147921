import React from 'react';
import Dashboard from './Dashboard';
import CustomizedSnackbars from './CustomizedSnackbar';

class DashboardPage extends React.Component{
  
  onSaveSubmit = (e, data) =>{
    e.preventDefault();

    const utccas = data.caspoyas.slice(3, 6);
    const utcmin = data.caspoyas.slice(7, 9)
 
    this.props.saveUser(data.lastName, data.firstName, data.patronymic, data.telefon, utccas, utcmin);
 
  }

  onClickLogout = (e) =>{
    localStorage.setItem('token', '');
    this.props.logout();  
  }  

  render() {

    return(
      <React.Fragment>
        <Dashboard 
          menuIndex = {this.props.menuIndex}
          leftMenu = {this.props.leftMenu}
          nastroiki = {this.props.nastroiki}
          tekMenuGroup = {this.props.tekMenuGroup}
          onClickGroupMenuSet = {this.props.onClickGroupMenu}
          onClickMenu = {this.props.onClickMenu}
          
          tabl = {this.props.tabl}
          tablsuccess = {this.props.tablsuccess}

          zapisatElement = {this.props.zapisatElement}
          deleteElement = {this.props.deleteElement}
          getTabl = {this.props.getTabl}
          errorZapisatEl = {this.props.errorZapisatEl}
          isFetchingZapisatEl = {this.props.isFetchingZapisatEl}
          
          priglUser = {this.props.priglUser}
          newError = {this.props.newError}

          setMasterPassword = {this.props.setMasterPassword}
          masterPassword = {this.props.masterPassword}

          isAuthenticated = {this.props.isAuthenticated}
          user = {this.props.user}
          onSaveSubmit = {this.onSaveSubmit}
          onClickLogout = {this.onClickLogout}        
        />


        <CustomizedSnackbars 
          msg={this.props.message.msg}
          openMsg = {this.props.message.openMsg}
          variantMsg={this.props.message.variantMsg}
          closeMsg = {this.props.closeMsg}
        /> 

      </React.Fragment>
    )
  }
}

export default DashboardPage;


