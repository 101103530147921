import * as types from '../constants/services';


export function newError(payload) {
  return (dispatch) =>{
    dispatch({
      type: types.NEW_ERROR,
      payload: {
        message: payload
      }  
    })
  }
}

export function setMasterPassword(payload) {
  return (dispatch) => {
    dispatch({
      type: types.SET_MASTER_PASSWORD,
      payload: payload
    })
  }
}

export function closeMsg() {
  return (dispatch) =>{
    dispatch({
      type: types.CLOSE_MSG
    })
  }
}

export function onClickMenu(payload) {
  return (dispatch) =>{
    dispatch({
      type: types.SET_MENU_INDEX,
      payload: payload
    })
  }
}

export function onClickGroupMenu(id) {
  return (dispatch, getState) =>{
    let name = 'Личные данные ';
    if (id) {
      const usergroups = getState().dashboard.tabl.tabl.usergroups.data;
      let usergroup = usergroups.find(item => item._id === id);
      name = usergroup.name + ' ';
    }
    

    dispatch({
      type: types.SET_MENU_GROUP,
      payload: {
        id: id,
        name: name,
      }
    })
  }
}
