import React from 'react';

import EditObertka from './EditObertka';

export default function Tab(props) {
  const [activTab, setActivTab] = React.useState(props.tabi[0].id);

  const onClickTab = (item, e) => {
    setActivTab(item);
  }

  const el3 = props.tabi.map((item, key) => {
    
    if (item.id===activTab) {

      switch(item.type) {
        case 'kalendar':

          return(
            <EditObertka
              key={item.id}
              data = {props.data}
              tablsuccess = {props.tablsuccess}
              zapisatElement = {props.zapisatElement}
              deleteElement = {props.deleteElement}              
              getTabl = {props.getTabl}
              errorZapisatEl = {props.errorZapisatEl}
              isFetchingZapisatEl = {props.isFetchingZapisatEl}
              isAuthenticated = {props.isAuthenticated}
              propPanel = {item}
              user={props.user}
              priglUser={props.priglUser}
              newError = {props.newError}
          />

          )
        case 'tabl':
          return(
            <EditObertka
              key={item.id}
              data = {props.data}
              tablsuccess = {props.tablsuccess}
              zapisatElement = {props.zapisatElement}
              deleteElement = {props.deleteElement}              
              getTabl = {props.getTabl}
              errorZapisatEl = {props.errorZapisatEl}
              isFetchingZapisatEl = {props.isFetchingZapisatEl}
              isAuthenticated = {props.isAuthenticated}
              propPanel = {item}
              user={props.user}
              priglUser={props.priglUser}
              tekMenuGroup = {props.tekMenuGroup}
              newError = {props.newError}
            />              
          )
        default:
          return(
            null
          )
      }
    } else {
      return (
        null
      )
    }


  })

  const elem1 = props.tabi.map((item, key)=>{
    let ic = 'fal ' + item.icon + ' ' + item.iconColor;
    let ass = 'nav-link fs-lg px-4 btn ';

    if (item.id===activTab) {
      ass = ass + 'active';

    }
    return(

      <li key={item.id} className="nav-item">
        <button href="#" id={item.id} onClick={()=>onClickTab(item.id)} className={ass}>
          <i className={ic} />
          <span className="hidden-sm-down ml-1">{item.name}</span>
        </button>
      </li>

      
    )
  })

  return(
    <React.Fragment>
    
      <ul className="nav nav-tabs">

        {elem1}


      </ul>
      <br />
      {el3}
    </React.Fragment>
  )
}
